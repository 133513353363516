<template>
  <div class="content-wrapper" v-loading="loading" element-loading-text="数据加载中...">
    <el-tabs class="art-tab" v-model="activeTabName" @tab-click="handleTabClick">
      <el-tab-pane label="已鉴证数据" name="first"></el-tab-pane>
      <el-tab-pane label="待鉴证数据" name="second"></el-tab-pane>
      <el-tab-pane label="未提交数据" name="third"></el-tab-pane>
    </el-tabs>
    <el-button class="btn-new" type="primary" @click="toNewUploadPage">新上传</el-button>
    <div class="art-list-block">
      <div v-if="userArts.length === 0" class="empty-block">
        <img class="img-no" src="@/page-home/assets/no-content.png" />
        <div class="tip">暂无上传数据</div>
      </div>
      <div class="upload-list" v-else>
        <div v-for="(item, index) in userArts" :key="index"
             class="item-art-wrapper" @click="handleArtItemClick(item)">
          <el-image class="img-cover" :src="item.coverImg" />
          <div class="right">
            <div class="title">
              {{item.title}}
            </div>
            <div class="cate-name">
              类别：{{item.cateName}}
            </div>
            <!--<div class="art-status art-status-saved">-->
            <!--  {{item.status | formatStatus}}-->
            <!--</div>-->
            <el-tag v-if="activeTabName==='first' && !loading"
                    :class="[item.isPass === 1?'tag-result':'tag-result-no']">{{item.isPass === 1 ? '通过' : '未通过'}}</el-tag>
          </div>
        </div>
      </div>

      <el-pagination v-if="userArts.length !== 0"
                     class="page-block" background
                     @current-change="handleCurrentChange"
                     :current-page="page.curPage"
                     :page-size="page.pageSize"
                     layout="total,prev,pager,next"
                     :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
  import {addNewArt, getUserArtsByPage} from "@/page-home/api/home";
  export default {
    name: "MyUpload",
    data() {
      return {
        loading: false,
        userArts: [],
        page: {
          curPage: 1,
          pageSize: 30
        },
        total: 0,
        activeTabName: 'first'
      }
    },
    filters: {
      formatStatus(value) {
        switch (value) {
          case 0: {
            return '待保存'
          }
          case 1: {
            return '待提交'
          }
          case 2: {
            return '审核中'
          }
          case 3: {
            return '审核完成'
          }
        }
      }
    },
    methods: {
      getMyUploads(activeTab) {
        if (this.loading) return
        const map = {
          'first': 3,
          'second': 2,
          'third': 1
        }
        activeTab = activeTab ? activeTab : 'first'
        this.loading = true
        const payload = {
          curPage: this.page.curPage,
          pageSize: this.page.pageSize
        }
        payload.status = map[activeTab]
        getUserArtsByPage(payload).then(res => {
          this.userArts = res.data.records || []
          this.total = parseInt(res.data.total)
        }).finally(() => {
          this.loading = false
        })
      },

      handleArtItemClick(item) {
        this.$router.push(`/upload?id=${item.artId}&v=${item.version}`)
      },

      toNewUploadPage() {
        addNewArt().then(res => {
          const id = res.data.artId
          this.$router.push(`/upload/new?id=${id}`)
        })
      },

      handleCurrentChange(val) {
        this.page.curPage = val
        this.getMyUploads(this.activeTabName)
      },

      handleTabClick(item) {
        this.activeTabName = item.name
        this.$store.commit('SET_USERUPLOAD_ACTIVE_TAB', item.name)
        this.page.curPage = 1
        this.getMyUploads(this.activeTabName)
      }
    },

    created() {
      this.activeTabName = this.$store.state.userUploadActiveTab
      this.getMyUploads(this.activeTabName)
    }
  }
</script>

<style lang="scss" scoped>
  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .art-tab {
    margin-top: 20px;
    /deep/ {
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #F0EDED;
      }
      .el-tabs__nav {
        margin-left: 40px;
      }
      .el-tabs__item {
        width: 180px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        text-align: center;
      }
      .el-tabs__item.is-active {
        color: #000000;
      }
      .el-tabs__active-bar {
        height: 3px;
      }
    }
  }

  .art-list-block {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .upload-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .page-block {
      align-self: flex-end;
      margin-top: 30px;
    }
    .empty-block {
      margin: 60px auto 20px;
      .tip {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #9D9D9E;
      }
    }
  }
  .item-art-wrapper {
    position: relative;
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 16px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    .img-cover {
      width: 118px;
      height: 88px;
    }
    .right {
      display: flex;
      flex-direction: column;
      .title {
        margin: 6px 14px;
        font-size: 22px;
        font-weight: 500;
        color: #000000;
      }
      .cate-name {
        margin-left: 14px;
        font-size: 14px;
      }
      .tag-result {
        margin-top: 10px;
        margin-left: 14px;
        font-size: 14px;
        text-align: center;
        color: #FA012A;
        border: 1px solid #EA002A;
        border-radius: 5px;
        background: none;
      }
      .tag-result-no {
        margin-top: 10px;
        margin-left: 14px;
        font-size: 14px;
        text-align: center;
        color: #004DF8;
        border: 1px solid #004DF8;
        border-radius: 5px;
        background: none;
      }
    }
    .art-status {
      position: absolute;
      top: 20px;
      right: -30px;
      width: 120px;
      text-align: center;
      transform: rotate(45deg);
    }
  }

  .btn-new {
    position: absolute;
    width: 120px;
    top: 16px;
    left: 750px;
  }
  .art-status-saved {
    color: #fff;
    background-color: red;
  }
</style>
